import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Button, Icon, Input, Modal, Pagination, Table, Label, Form } from "semantic-ui-react";
import agent from "../../api/agent";
import { toast } from "react-toastify";

function SchoolsList({
  schools,
  currentPage,
  pageSize,
  totalSchoolCount,
  onSchoolSelected,
  onDelete,
  onPageChanged,
  blockActions,
  currentSortDirection,
}) {
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmedPassword, setConfirmedPassword] = useState(null);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [passwordResetInProgress, setPasswordResetInProgress] = useState(false);

  const navigateToPage = (pageIndex) => {
    onPageChanged(pageIndex, currentSortDirection);
  };

  const resetSchoolPassword = () => {
    setPasswordResetInProgress(true);
    agent.Schools.resetSchoolPassword(selectedSchool.id, newPassword)
      .then((result) => {
        toast.success("School password was reset sucessfully");
        setShowResetPasswordModal(false);
        setPasswordResetInProgress(false);
      })
      .catch((error) => {
        toast.success("Failed to reset school password");
        setPasswordResetInProgress(false);
      });
  };

  const sortSchools = () => {
    if (currentSortDirection === "ascending") {
      onPageChanged(currentPage, "descending");
    } else if (currentSortDirection === "descending" || currentSortDirection === "") {
      onPageChanged(currentPage, "ascending");
    }
  };

  const gridPaging = (
    <Pagination
      disabled={blockActions}
      boundaryRange={0}
      defaultActivePage={currentPage}
      totalPages={Math.ceil(totalSchoolCount / pageSize)}
      onPageChange={(e, d) => navigateToPage(d.activePage)}
    />
  );

  const resetPasswordModal = (
    <Modal open={showResetPasswordModal}>
      <Modal.Header>Reset School Password</Modal.Header>
      <Modal.Content>
        <Modal.Description>
        <Form className="pass-reset">
    <Form.Group widths='equal'>
    <Form.Input
            autoComplete="new-password"
            label="New Password"
            error='Minimum password requirement: 8 characters'
            type={showPassword ? "text" : "password"}
            onChange={(event) => {
              setNewPassword(event.target.value);
            }}
            icon={
              <Icon
                name={showPassword ? "eye slash" : "eye"}
                link
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
            }
          />
          <Form.Input
            autoComplete="new-password"
            label="Confirm Password"
            onChange={(event) => {
              setConfirmedPassword(event.target.value);
            }}
            type={showConfirmedPassword ? "text" : "password"}
            icon={
              <Icon
                name={showConfirmedPassword ? "eye slash" : "eye"}
                link
                onClick={() => {
                  setShowConfirmedPassword(!showConfirmedPassword);
                }}
              />
            }
          />
          </Form.Group>
  </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            setConfirmedPassword("");
            setNewPassword("");
            setShowResetPasswordModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={newPassword == null || newPassword === "" || newPassword !== confirmedPassword || newPassword.length<8}
          color="green"
          loading={passwordResetInProgress}
          onClick={() => {
            resetSchoolPassword();
          }}
          positive
        >
          Reset
        </Button>
      </Modal.Actions>
    </Modal>
  );

  return (
    <>
      {showResetPasswordModal && resetPasswordModal}
      <Table celled padded selectable sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>School</Table.HeaderCell>
            <Table.HeaderCell>Country</Table.HeaderCell>
            <Table.HeaderCell>Licenses</Table.HeaderCell>
            <Table.HeaderCell
              disabled={blockActions}
              sorted={currentSortDirection}
              onClick={() => {
                sortSchools();
              }}
            >
              Expiry
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {schools &&
            schools.map((school) => (
              <Table.Row key={school.id}>
                <Table.Cell>
                  <Link to={`/myschools/${school.id}`}>{school.name}</Link>
                </Table.Cell>
                <Table.Cell textAlign="left">{school.country}</Table.Cell>
                <Table.Cell textAlign="left">{school.licenseCount}</Table.Cell>
                <Table.Cell textAlign="left">{school.licenseExpiryDateFormated}</Table.Cell>
                <Table.Cell textAlign="right">
                  <div>
                    <Icon
                      bordered
                      title="Password Reset"
                      name="user secret"
                      color="orange"
                      onClick={() => {
                        setSelectedSchool(school);
                        setShowResetPasswordModal(true);
                      }}
                    />
                    <NavLink to={`/myschools/${school.id}`}>
                      <Icon title="Manage User" bordered color="blue" name="user" />
                    </NavLink>
                    <Icon
                      bordered
                      title="Edit"
                      color="green"
                      name="edit"
                      onClick={() => {
                        onSchoolSelected(school);
                      }}
                    />
                    <Icon
                      bordered
                      color="red"
                      title="Delete"
                      name="delete"
                      onClick={() => onDelete(school)}
                    />
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {totalSchoolCount > 10 && gridPaging}
    </>
  );
}

export default SchoolsList;
