import * as Yup from "yup";
import React, { useState } from "react";
import { Formik, Form } from "formik";
import { Button, Container, Header, Icon, Checkbox } from "semantic-ui-react";
import agent from "../../api/agent";
import CustomDateInput from "../../common/CustomDateInput";
import CustomTextInput from "../../common/CustomTextInput";

export default function AddEditSchool({ school: selectedSchool, onClose }) {
  const [showPassword, setShowPassword] = useState(false);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const initialState = selectedSchool
    ? {
        ...selectedSchool,
        userName: "",
        password: "",
        licenseExpiryDate: new Date(selectedSchool.licenseExpiryDate),
      }
    : {
        id: 0,
        name: "",
        contactEmail: "",
        licenseCount: 0,
        licenseExpiryDate: "",
        country: "",
        userName: "",
        password: "",
      };

  const validationSchema = Yup.object({
    name: Yup.string().required("The school name is required"),
    contactEmail: Yup.string()
      .required("The school contact email is required")
      .email("Invalid email format"),
    country: Yup.string().required("The country field is required"),
    licenseCount: Yup.number("Invalid number format").required("The license count field is required"),
    licenseExpiryDate: Yup.date("Invalid date format").required("Expiry date field is required"),
    userName: Yup.string().when("selectedSchool", {
      is: (value) => selectedSchool == null,
      then: Yup.string()
        .required("Username field is required")
        .min(5, "Username should be at least 5 characters"),
    }),
    password: Yup.string().when("selectedSchool", {
      is: (value) => selectedSchool == null,
      then: Yup.string()
        .required("Password field is required")
        .min(8, "Password should be at least 8 characters"),
    }),
  });

  const submitForm = (schoolValues) => {
    setIsSaving(true);
    schoolValues.id === 0
      ? agent.Schools.create(schoolValues)
          .then((result) => {
            if (sendWelcomeEmail) agent.Schools.sendNewSchoolWelcomeEmail(schoolValues);
            onClose(true);
          })
          .catch((error) => {
            setIsSaving(false);
          })
      : agent.Schools.update(schoolValues)
          .then((result) => {
            onClose(true);
          })
          .catch((error) => {
            setIsSaving(false);
          });
  };

  return (
    <>
      <div className="sidebar-header">
        <Header as="h2" floated="left" className="">
          {selectedSchool ? "Edit School" : "New School"}
        </Header>
      </div>
      <Container clearing>
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialState}
          onSubmit={(values) => submitForm(values)}
        >
          {({ values: school, handleChange, handleSubmit }) => (
            <Form className="ui form" autoComplete="off" onSubmit={handleSubmit}>
              <CustomTextInput name="name" placeholder="School Name" labeltext="Name" />
              <CustomTextInput
                placeholder="School Email"
                name="contactEmail"
                labeltext="Contact Email"
              />
              <CustomTextInput placeholder="Country" name="country" labeltext="Country" />
              <CustomTextInput
                placeholder="License Count"
                name="licenseCount"
                labeltext="License Count"
              />
              <CustomDateInput
                placeholderText="Expires"
                name="licenseExpiryDate"
                showTime={false}
                dateFormat="dd-MMM-yyyy"
              />
              {selectedSchool == null && (
                <CustomTextInput
                  placeholder="Username"
                  name="userName"
                  labeltext="Username"
                  autoComplete="new-password"
                />
              )}
              {selectedSchool == null && (
                <CustomTextInput
                  autoComplete="new-password"
                  placeholder="Password"
                  name="password"
                  labeltext="password"
                  icon={
                    <Icon
                      name={showPassword ? "eye slash" : "eye"}
                      link
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    />
                  }
                  type={showPassword ? "text" : "password"}
                />
              )}
              <div className="send-mail">
                <input type="checkbox" onChange={(e) => setSendWelcomeEmail(e.target.checked)} />
                {"Send a welcome email"}
              </div>
              <Button floated="right" type="submit" positive content="Submit" loading={isSaving} />
              {!isSaving && (
                <Button
                  floated="right"
                  type="button"
                  content="Cancel"
                  onClick={() => {
                    onClose(false);
                  }}
                />
              )}
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
