import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Progress, Segment, Table, Message } from "semantic-ui-react";
import agent from "../../api/agent";
import { useStore } from "../../Stores/store";
import { useParams } from "react-router";
import { toast } from "react-toastify";

export default function UserProvisioningProgress() {
  const { bulkUploadStore } = useStore();
  let { schoolId } = useParams();
  const [users, setUsers] = useState([...bulkUploadStore.users]);
  const [taskCompleted, setTaskCompleted] = useState(false);
  const [provisionedUsersCount, setProvisionedUsersCount] = useState(0);
  const [affectedUsers, setAffectedUsers] = useState([]);
  const [errorExists, setErrorExists] = useState(false);

  useEffect(() => {
    if (bulkUploadStore.users == null || bulkUploadStore.users.length === 0) return;
    provisionUser(0, users, []);
    return;
  }, []);

  const provisionUser = (index, usersProgress, modifiedUsers) => {
    if (users == null || users.length === 0) return;

    let user = users[index];
    let progress = [...usersProgress];
    let modified = [...modifiedUsers];

    agent.Students.createBulkUser(user)
      .then((result) => {
        progress = progress.map((item) => {
          if (result.userName === item.userName) {
            let newItem = { ...item };
            newItem.uploadStatus = result.uploadStatus;
            newItem.newUserName = result.newUserName;
            return newItem;
          }
          return item;
        });
        setUsers(progress);
        setProvisionedUsersCount(index + 1);
        if (result.userName !== result.newUserName) {
          modified.push(result);
          setAffectedUsers(modified);
        }

        if (index < users.length - 1) provisionUser(index + 1, progress, modified);
        else {
          setTaskCompleted(true);
          bulkUploadStore.clearUsersList();
          agent.Schools.reportProvisioning(schoolId, usersProgress, modified)
            .then((result) => {
              toast.success("Provisioning completed successfully");
            })
            .catch((error) => {
              toast.error("Failed to send report mail!");
            });
        }
      })
      .catch((error) => {
        toast.error("Failed while creating user " + user.userName);
        setErrorExists(true);
      });
  };

  return (
    <>
      <Segment style={{ overflow: "auto", maxHeight: "100%" }}>
        <h2>{taskCompleted ? "Provisioning  Finished" : "Provisioning In Progress ..."}</h2>
        <Progress
          className="prov-progress-bar"
          value={provisionedUsersCount}
          total={users.length}
          progress="percent"
          success={taskCompleted}
          error={errorExists}
        />
        <Button.Group>
          <Button>{provisionedUsersCount}</Button>
          <Button.Or text="/" />
          <Button positive>{users.length}</Button>
        </Button.Group>
        <Message color="yellow">Do not close this window until the provisioning has completed.</Message>
        {affectedUsers != null && affectedUsers.length > 0 && (
          <Table celled padded selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="left">Username</Table.HeaderCell>
                <Table.HeaderCell textAlign="left">Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {affectedUsers.map((user) => (
                <Table.Row key={user.userName}>
                  <Table.Cell textAlign="left">
                    {user.userName !== user.newUserName
                      ? user.userName + "    >>Renamed>>   " + user.newUserName
                      : user.newUserName}
                  </Table.Cell>
                  <Table.Cell textAlign="left">{user.uploadStatus}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        <div>
          <Button as={NavLink} to={`/myschools/${schoolId}`} disabled={!taskCompleted && !errorExists}>
            Go back to school
          </Button>
        </div>
      </Segment>
    </>
  );
}
