import axios from "axios";
import { toast } from "react-toastify";
import { store } from "../Stores/store";
import { history } from "../index";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const responseBody = (response) => response.data;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error == null || error.response == null) return Promise.reject(error);

    const { status } = error.response;
    switch (status) {
      case 400:
        if (error.response && error.response.data) toast.error(error.response.data);
        else toast.error("Bad Request");
        break;
      case 401:
        toast.error("Unauthorized");
        break;
      case 404:
        history.push("/not-found");
        break;
      case 500:
        toast.error("Server Error");
        break;
      default:
        break;
    }
    return Promise.reject(error);
  }
);

const requests = {
  get: (url) => axios.get(url).then(responseBody),
  post: (url, body) => axios.post(url, body).then(responseBody),
  put: (url, body) => axios.put(url, body).then(responseBody),
  delete: (url) => axios.delete(url).then(responseBody),
};

const Schools = {
  list: (filter, pageIndex, pageSize, direction) =>
    requests.get(
      `/schools?filter=${filter}&pageIndex=${pageIndex}&pageSize=${pageSize}&direction=${direction}`
    ),
  count: (filter) => requests.get(`/schools/count?filter=${filter}`),
  details: (schoolId) => requests.get(`/schools/${schoolId}`),
  create: (school) => requests.post("/schools", school),
  update: (school) => requests.put("/schools/", school),
  delete: (schoolId) => requests.delete(`/schools/${schoolId}`),
  getLicenseSummary: (schoolId) => requests.get(`/schools/${schoolId}/licensedetails`),
  resetSchoolPassword: (schoolId, newPassword) =>
    requests.put(`/schools/resetpassword`, { SchoolId: schoolId, NewPassword: newPassword }),
  reportProvisioning: (schoolId, allProvisionedUsers, renamedUsers) =>
    requests.post(`/schools/${schoolId}/reportprovisioning`, {
      AllProvisionedUsers: allProvisionedUsers,
      RenamedUsers: renamedUsers,
    }),
  sendNewSchoolWelcomeEmail: (school) => requests.post("/schools/sendwelcomemail", school),
};

const Accounts = {
  current: () => requests.get("/api/account"),
  login: (user) => requests.post("/api/account/login", user),
};

const Admins = {
  list: () => requests.get("/admin"),
  create: (admin) => requests.post("/admin", admin),
  delete: (adminId) => requests.delete(`/admin/${adminId}`),
};

const FileDownload = require("js-file-download");
const Students = {
  list: (schoolId, filter, pageIndex, pageSize) =>
    requests.get(
      `/students?schoolId=${schoolId}&filter=${filter}&pageIndex=${pageIndex}&pageSize=${pageSize}`
    ),
  create: (student) => requests.post(`/students`, student),
  createBulkUser: (student) => requests.post(`/students/addbulkuser`, student),
  count: (schoolId, filter) => requests.get(`/students/count?schoolId=${schoolId}&filter=${filter}`),
  delete: (users) => requests.post(`/students/delete`, users),
  updateStatus: (studentIds, status) =>
    requests.put(`students/updatestatus/${status ? "enable" : "disable"}`, studentIds),
  resetPassword: (userId, newPassword) =>
    requests.put(`/students/resetpassword`, { UserId: userId, NewPassword: newPassword }),
  export: (schoolId) => {
    axios({
      url: `Students/export?schoolId=${schoolId}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      FileDownload(response.data, "users.csv");
    });
  },
};

const ContentFiles = {
  open: (file) => requests.get("files/" + file),
};

const agent = {
  Schools,
  Accounts,
  Students,
  Admins,
  ContentFiles,
};

export default agent;
