import React from "react";
import { Image, Container, Header, Segment, Grid, Button } from "semantic-ui-react";
import { HashLink } from "react-router-hash-link"
import { NavLink } from "react-router-dom";

export default function SuggestedActivities() {
  return (
    <>
      <div className="SuggestedActivities">
      <NavLink to="/">
        <Button className="back-to-btn" color='yellow' floated='right'>Back to Homepage</Button>
        </NavLink>
        <Header as="h2">Suggested Activities to Support the App</Header>
        <Container fluid textAlign="left">
          <Segment inverted>
          <Grid>
           <Grid.Column width={4}>
           <Image src="/assets/icons1.png" size="medium" verticalAlign="middle" floated="left" />{" "}
           </Grid.Column>
           <Grid.Column width={12}>
           <span>
              <strong>Text Detectives</strong> –<br></br> As well as reading and writing skills, the APP can be used to
              benefit oral literacy and spoken vocabulary. After completing an activity, children could
              attempt to summarise the key points of the story into 5 spoken sentences. Added challenge
              could be incorporated if they use any of the new key vocab acquired.
            </span>
           </Grid.Column>
          </Grid>
          </Segment>
        </Container>
        <Container fluid textAlign="left">
          <Segment inverted>
          <Grid>
           <Grid.Column width={4}>
           <Image src="/assets/icons2.png" size="medium" verticalAlign="middle" floated="left" />{" "}
           </Grid.Column>
           <Grid.Column width={12}>
           <span>
              <strong>Synonym Search</strong> –<br></br> Ask children to work through an activity and note down the key
              vocabulary acquired throughout. Once they have completed the games in the APP, ask them to
              choose a word and challenge themselves to search and suggest synonyms for this word, e.g.
              ‘big’ = large, giant, huge, humongous, massive, enormous.
            </span>
           </Grid.Column>
          </Grid>
          </Segment>
        </Container>
        <Container fluid textAlign="left">
          <Segment inverted>
          <Grid>
           <Grid.Column width={4}>
           <Image src="/assets/icons3.png" size="medium" verticalAlign="middle" floated="left" />{" "}
           </Grid.Column>
           <Grid.Column width={12}>
           <span>
              <strong>Tense Transition</strong> –<br></br>
              Most of the questions within an activity on the APP will fall within the same verb tense,
              i.e. past or present. For this activity, ask students to select some of the vocabulary from
              the text then try to change these into alternative verb tense forms, e.g. ‘ate’ = eats,
              eating, will eat
            </span>
           </Grid.Column>
          </Grid>
          </Segment>
        </Container>
        <Container fluid textAlign="left">
          <Segment inverted>
          <Grid>
           <Grid.Column width={4}>
           <Image src="/assets/icons4.png" size="medium" verticalAlign="middle" floated="left" />{" "}
           </Grid.Column>
           <Grid.Column width={12}>
           <span>
              <strong>Opposite Adjectives</strong> –<br></br>
              Work through an activity noting down any of the adjectives highlighted within the matching
              games. After completing the rounds, challenge students to ‘swap’ the adjectives for their
              opposites, e.g. ‘tall’ – short / ‘high’ – low
            </span>
           </Grid.Column>
          </Grid>
          </Segment>
        </Container>
        <Container fluid textAlign="left">
          <Segment inverted>
          <Grid>
           <Grid.Column width={4}>
           <Image src="/assets/icons5.png" size="medium" verticalAlign="middle" floated="left" />{" "}
           </Grid.Column>
           <Grid.Column width={12}>
           <span>
              <strong>Story Summary</strong> –<br></br>
              As well as reading and writing skills, the APP can be used to benefit oral literacy and
              spoken vocabulary. After completing an activity, children could attempt to summarise the
              key points of the story into 5 spoken sentences. Added challenge could be incorporated if
              they use any of the new key vocab acquired.
            </span>
           </Grid.Column>
          </Grid>
          </Segment>
        </Container>
        <Container fluid textAlign="left">
          <Segment inverted>
          <Grid>
           <Grid.Column width={4}>
           <Image src="/assets/icons6.png" size="medium" verticalAlign="middle" floated="left" />{" "}
           </Grid.Column>
           <Grid.Column width={12}>
           <span>
              <strong>Story Stimulus</strong> –<br></br>
              One of the methods popular within UK education at present is the ‘Talk for Writing’
              framework developed by Pie Corbett<sup><HashLink smooth to="/studentscenter/SuggestedActivities#footnote">v</HashLink></sup>. This involves children reading and re-reading a text
              until they are able to internalise the language then using this as a stimulus for their own
              writing, thereby imitating the original. In the same way, the texts within our APP can be
              used for unlimited creative writing activities, using the characters in their own scenarios
              and stories.
            </span>
           </Grid.Column>
          </Grid>
          </Segment>
        </Container>
        <Container fluid textAlign="left">
          <Segment inverted>
          <Grid>
           <Grid.Column width={4}>
           <Image src="/assets/icons7.png" size="medium" verticalAlign="middle" floated="left" />{" "}
           </Grid.Column>
           <Grid.Column width={12}>
           <span>
              <strong>Question Creation</strong> –<br></br>A great comprehension-based activity which would work extremely
              well as a peer/partner-based activity is the creation of their own questions focused on the
              text. Students watch and read the story then write their own questions to ask each other,
              focusing on key content and vocabulary.
            </span>
           </Grid.Column>
          </Grid>
          </Segment>
        </Container>
        <Container id="footnote" textAlign="left">
          <p> 
          <sup>v</sup><a href="https://www.talk4writing.com/" target={"_blank"}> https://www.talk4writing.com/</a>
          </p>
        </Container>
      </div>
    </>
  );
}
