import { makeAutoObservable } from "mobx";

export default class BulkUploadStore {
  usersList = null;
  constructor() {
    makeAutoObservable(this);
  }

  setUsersList = (users) => {
    this.usersList = users;
  };

  clearUsersList = () => {
    this.usersList = [];
  };

  get users() {
    return this.usersList;
  }
}
