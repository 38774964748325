import React, { useState } from "react";
import { Table, Checkbox, Pagination } from "semantic-ui-react";

export default function StudentsList({
  students,
  currentPage,
  pageSize,
  totalUserCount,
  onSelectedUserChanged,
  onPageChanged,
  blockActions,
}) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const selectUser = (user) => {
    if (selectedUsers.filter((u) => u.id === user.id).length > 0) {
      var updatedList = selectedUsers.filter((u) => u.id !== user.id);
      setSelectedUsers(updatedList);
      onSelectedUserChanged(updatedList);
    } else {
      var list = [...selectedUsers, user];
      setSelectedUsers(list);
      onSelectedUserChanged(list);
    }
  };

  const selectAllUsers = () => {
    if (selectedUsers.length === 0 || selectedUsers.length < students.length) {
      setSelectedUsers([...students]);
      onSelectedUserChanged([...students]);
    } else {
      setSelectedUsers([]);
      onSelectedUserChanged([]);
    }
  };

  const navigateToPage = (pageIndex) => {
    onPageChanged(pageIndex);
  };

  const gridPaging = (
    <Pagination
      disabled={blockActions}
      boundaryRange={0}
      defaultActivePage={currentPage}
      totalPages={Math.ceil(totalUserCount / pageSize)}
      onPageChange={(e, d) => navigateToPage(d.activePage)}
    />
  );

  return (
    <>
      <Table celled padded selectable disabled={blockActions}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell collapsing>
              <Checkbox
                onChange={() => selectAllUsers()}
                checked={students && students.length !== 0 && selectedUsers.length === students.length}
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Username</Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {students &&
            students.map((student) => (
              <Table.Row key={student.id}>
                <Table.Cell textAlign="left" collapsing>
                  <Checkbox
                    onChange={() => selectUser(student)}
                    checked={selectedUsers.filter((u) => u.id === student.id).length > 0}
                  />
                </Table.Cell>
                <Table.Cell textAlign="left">{student.userName}</Table.Cell>
                <Table.Cell textAlign="left">{student.isActive ? "Active" : "DeActivated"}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {totalUserCount > 10 && gridPaging}
    </>
  );
}
