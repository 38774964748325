import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import agent from "../api/agent";

export default observer(function FileViewer(props) {
  let { fileName } = useParams();
  const [fileContent, setFileContent] = useState();

  useEffect(() => {
    agent.ContentFiles.open(fileName).then((result) => {
      setFileContent(result);
    });
  }, [fileName]);
  return (
    <>
      <object data={fileContent} width="100%" height={1500} type="application/pdf">
        Loading ...
      </object>
    </>
  );
});
