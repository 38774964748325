import "./App.css";
import { Container, Segment, Grid, Image, List, Header } from "semantic-ui-react";
import { Route, Switch, useHistory } from "react-router";
import { useStore } from "./Stores/store";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import Home from "./Containers/HomePage/Home";
import LoginForm from "./Containers/UserLogin/LoginForm";
import TopNavigation from "./Layout/TopNavigation";
import ManageSchools from "./Containers/SchoolManagement/ManageSchools.js";
import ModalContainer from "./Layout/ModalContainer";
import LoadingComponent from "./Layout/LoadingComponent";
import ManageUsers from "./Containers/UserManagement/ManageUsers";
import NotFound from "./common/Errors/NotFound";
import { ToastContainer } from "react-toastify";
import StudentContent from "./Containers/Student/StudentConent";
import UserProvisioningProgress from "./Containers/UserManagement/UserProvisioningProgress";
import AdminSettings from "./Containers/SettingsManagement/AdminSettings";
import HowTo from "./Containers/Student/HowTo";
import OurFramework from "./Containers/Student/OurFramework";
import WordsPracticed from "./Containers/Student/WordsPracticed";
import SuggestedActivities from "./Containers/Student/SuggestedActivities";
import FileViewer from "./common/FileViewer";

function App() {
  const history = useHistory();
  const { commonStore, userStore } = useStore();
  useEffect(() => {
    if (commonStore.token) userStore.getUser().finally(() => commonStore.setAppLoaded());
    else commonStore.setAppLoaded();
  }, [commonStore, userStore]);

  if (!commonStore.appLoaded) return <LoadingComponent content="Loading app..." />;
  if (!userStore.isLoggedIn && history.path !== "/") history.push("/");

  return (
    <>
      <ToastContainer position="bottom-right" hideProgressBar />
      <div
        className="App"
        style={{
          backgroundImage: `url(${"/assets/app-bg.png"})`,
          fontWeight: "normal",
        }}
      >
        <ModalContainer />
        <Route exact path="/" component={Home} />
        <Route
          path="/(.+)"
          render={() => (
            <>
              <Container>
                <TopNavigation />
                <Switch>
                  <Route exact path="/login" component={LoginForm} />
                  <Route exact path="/studentscenter/HowTo">
                    <Segment className="maincontainer">
                      <HowTo />
                    </Segment>
                  </Route>
                  <Route exact path="/studentscenter/Ourframework">
                    <Segment className="maincontainer">
                      <OurFramework />
                    </Segment>
                  </Route>
                  <Route exact path="/studentscenter/WordsPracticed">
                    <Segment className="maincontainer">
                      <WordsPracticed />
                    </Segment>
                  </Route>
                  <Route exact path="/studentscenter/SuggestedActivities">
                    <Segment className="maincontainer">
                      <SuggestedActivities />
                    </Segment>
                  </Route>
                  <Route path="/studentscenter/files/:fileName">
                    <Segment className="maincontainer">
                      <FileViewer />
                    </Segment>
                  </Route>
                  <Route path="/studentscenter">
                    <Segment className="maincontainer">
                      <StudentContent />
                    </Segment>
                  </Route>
                  {userStore.user && userStore.user.userRole === "LPADMIN" && (
                    <Route exact path="/myschools">
                      <Segment className="maincontainer">
                        <ManageSchools />
                      </Segment>
                    </Route>
                  )}
                  {userStore.user && userStore.user.userRole !== "STUDENT" && (
                    <Route path="/myschools/:schoolId">
                      <Segment className="maincontainer">
                        <ManageUsers />
                      </Segment>
                    </Route>
                  )}
                  {userStore.user && userStore.user.userRole !== "STUDENT" && (
                    <Route path="/:schoolId/bulkupload">
                      <Segment className="maincontainer">
                        <UserProvisioningProgress />
                      </Segment>
                    </Route>
                  )}
                  {userStore.user && userStore.user.userRole === "LPADMIN" && (
                    <Route exact path="/settings">
                      <Segment className="maincontainer">
                        <AdminSettings />
                      </Segment>
                    </Route>
                  )}
                  <Route component={NotFound} />
                </Switch>
              </Container>
              <Container className="li-footer">
                <Grid columns="equal">
                  <Grid.Row>
                    <Grid.Column>
                      <Image src="/assets/lp-logo-white-n-yellow.png" size="medium" />
                      <div className="li-footer-links">
                        <a href="http://www.learningislands.com/privacy-policy/" target={"_blank"}>Privacy Policy</a>
                        <a href="http://www.learningislands.com/terms-conditions/" target={"_blank"}>Terms & Conditions</a>
                      </div>
                    </Grid.Column>
                    <Grid.Column></Grid.Column>

                    <Grid.Column className="li-footer-social">
                      <List horizontal>
                        <List.Item as="a" href="https://www.facebook.com/gaming/learningislands" target={"_blank"}>
                          <Image src="/assets/fbwhite.png" />
                        </List.Item>
                        <List.Item as="a" href="https://www.instagram.com/learningislands/?hl=en" target={"_blank"}>
                          <Image src="/assets/instagramwhite.png" />
                        </List.Item>
                        <List.Item as="a" href="https://www.youtube.com/channel/UCTUyZvjNmzfq9k4XnRVFfOA" target={"_blank"}>
                          <Image src="/assets/ytwhite.png" />
                        </List.Item>
                      </List>
                    </Grid.Column>
                    <Grid.Column>
                      <a href="http://www.learningislands.com/lpdisney/download-the-app/" target={"_blank"}>
                        <Image src="/assets/google-play-badge-Custom.png" size="small" />
                      </a>
                      <a href="http://www.learningislands.com/lpdisney/download-the-app/" target={"_blank"}>
                        <Image src="/assets/app-store-Custom.png" size="small" />
                      </a>
                      <div className="li-footer-links">© Learning Possibilities 2022</div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Header as="h6" textAlign="center" color="white">
                  Learning Islands and Learning Possibilities are Trademarks of The Learning
                  Possibilities Group. All rights reserved.
                </Header>
              </Container>
            </>
          )}
        />
      </div>
    </>
  );
}

export default observer(App);
