import React, { useState } from "react";
import { Confirm, Icon, Table } from "semantic-ui-react";
import { useStore } from "../../Stores/store";
import agent from "../../api/agent";

export default function AdminsList({ admins, onUserDeleted }) {
  const deleteAdmin = (admin) => {
    setSelectedUser(admin);
    setConfirmDeleteUser(true);
  };
  const { userStore } = useStore();
  const [confirmDeleteUser, setConfirmDeleteUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const confirmDeleteUserCallBack = () => {
    agent.Admins.delete(selectedUser.id)
      .then((result) => {
        setSelectedUser(null);
        setConfirmDeleteUser(false);
        onUserDeleted();
      })
      .catch((error) => {
        setConfirmDeleteUser(false);
      });
  };

  const cancelDeleteUserCallBack = () => {
    setSelectedUser(null);
    setConfirmDeleteUser(false);
  };

  const confirmDeleteModal = (
    <Confirm
      header="You are about to delete the selected admin"
      open={confirmDeleteUser}
      onCancel={() => cancelDeleteUserCallBack()}
      onConfirm={() => confirmDeleteUserCallBack()}
    />
  );

  return (
    <>
      {confirmDeleteModal}
      <Table celled padded selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>UserName</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {admins &&
            admins.map((admin) => (
              <Table.Row key={admin.id}>
                <Table.Cell textAlign="left">{admin.userName}</Table.Cell>
                <Table.Cell textAlign="right">
                  <div>
                    {userStore.user && userStore.user.userName !== admin.userName && (
                      <Icon
                        bordered
                        color="red"
                        title="Delete"
                        name="delete"
                        onClick={() => deleteAdmin(admin)}
                      />
                    )}
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
