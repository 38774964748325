import React from "react";
import { Image, Card, Container } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

export default function StudentContent() {
  return (
    <>
      <Image src="/assets/LearningIslandsHub.png" fluid />
      <div className="edu-pages">
        <Container>
          <Card.Group itemsPerRow={4}>
            <Card>
              <NavLink to="/studentscenter/HowTo">
                <Image src="/assets/howto.png" size="small" wrapped ui={false} />
                <Card.Content>
                  <Card.Header>How To</Card.Header>
                </Card.Content>
              </NavLink>
            </Card>
            <Card>
              <NavLink to="/studentscenter/Ourframework">
                <Image src="/assets/framework.png" size="small" wrapped ui={false} />
                <Card.Content>
                  <Card.Header>Our Framework</Card.Header>
                </Card.Content>
              </NavLink>
            </Card>
            <Card>
              <NavLink to="/studentscenter/SuggestedActivities">
                <Image src="/assets/activities.png" size="small" wrapped ui={false} />
                <Card.Content>
                  <Card.Header>Suggested Activities</Card.Header>
                </Card.Content>
              </NavLink>
            </Card>
            <Card>
              <NavLink to="/studentscenter/WordsPracticed">
                <Image src="/assets/words.png" size="small" wrapped ui={false} />
                <Card.Content>
                  <Card.Header>Words Practiced</Card.Header>
                </Card.Content>
              </NavLink>
            </Card>
          </Card.Group>
        </Container>
      </div>
    </>
  );
}
