import { useField } from "formik";
import React from "react";
import { Form, Label, Input } from "semantic-ui-react";

export default function CustomTextInput(props) {
  const [field, meta] = useField(props.name);
  return (
    <>
      <Form.Field error={meta.touched && !!meta.error}>
        <label>{props.labeltext}</label>
        <Input {...field} {...props} />
        {meta.touched && meta.error ? (
          <Label basic color="red">
            {meta.error}
          </Label>
        ) : null}
      </Form.Field>
    </>
  );
}
