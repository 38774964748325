import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Button, Icon } from "semantic-ui-react";
import agent from "../../api/agent";
import CustomTextInput from "../../common/CustomTextInput";
import { toast } from "react-toastify";

export default function AddAdmin({ onClose }) {
  const [isSaving, setIsSaving] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const initialState = {
    userName: "",
    password: "",
  };

  const validationSchema = Yup.object({
    userName: Yup.string().required("Username field is required"),
    password: Yup.string()
      .required("Password field is required")
      .min(4, "Password should be at least 4 characters"),
  });

  const submitForm = (userValues) => {
    setIsSaving(true);
    agent.Admins.create(userValues)
      .then((result) => {
        toast.success("Admin created successfully");
        setIsSaving(false);
        onClose(true);
      })
      .catch((error) => {
        setIsSaving(false);
      });
  };

  return (
    <>
      <input
        type="text"
        id="username"
        name="username"
        style={{ width: 0, height: 0, visibility: "hidden", position: "absolute", left: 0, top: 0 }}
      />
      <input
        type="password"
        id="password"
        name="password"
        style={{ width: 0, height: 0, visibility: "hidden", position: "absolute", left: 0, top: 0 }}
      />
      <Formik
        enableReinitialize
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={(values) => submitForm(values)}
      >
        {({ values: user, handleChange, handleSubmit }) => (
          <Form className="ui form" autoComplete="new-password" onSubmit={handleSubmit}>
            <CustomTextInput
              placeholder="Username"
              name="userName"
              label="Username"
              autoComplete="new-password"
            />
            <CustomTextInput
              placeholder="Password"
              name="password"
              label="password"
              autoComplete="new-password"
              type={showPassword ? "text" : "password"}
              icon={
                <Icon
                  name={showPassword ? "eye slash" : "eye"}
                  link
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />
              }
            />
            <Button
              color="teal"
              floated="right"
              type="submit"
              positive
              content="Submit"
              loading={isSaving}
            />
            {!isSaving && (
              <Button
                floated="right"
                type="button"
                content="Cancel"
                onClick={() => {
                  onClose(false);
                }}
              />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}
