import { makeAutoObservable } from "mobx";

export default class SchoolStore {
  currentSchool = null;

  constructor() {
    makeAutoObservable(this);
  }
  setSelectedSchool = (school) => {
    this.currentSchool = school;
  };

  get current() {
    return this.currentSchool;
  }
}
