import React, { useState, useEffect } from "react";
import agent from "../../api/agent";
import {
  Loader,
  Dimmer,
  Container,
  Button,
  Icon,
  Header,
  Grid,
  Segment,
  Sidebar,
  Menu,
  Confirm,
  Input,
  Dropdown,
  GridRow,
  Table,
} from "semantic-ui-react";
import SchoolsList from "./SchoolsList";
import { observer } from "mobx-react-lite";
import AddEditSchool from "./AddEditSchool";
import { toast } from "react-toastify";

function ManageSchools() {
  const [pageSize, setPageSize] = useState(25);
  const [schools, setSchools] = useState([]);
  const [isLoading, setLoadingStatus] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [confirmDeleteSchool, setConfirmDeleteSchool] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [totalSchoolCount, setTotalSchoolCount] = useState(0);
  const [schoolFilterQuery, setSchoolFilterQuery] = useState("");
  const [blockActions, setBlockActions] = useState(false);
  const [sortDirection, setSortDirection] = useState("ascending");

  const loadSchools = (pageIndex = currentPageIndex, showProgress = true) => {
    setBlockActions(true);
    if (showProgress) setLoadingStatus(true);
    agent.Schools.list(schoolFilterQuery, pageIndex, pageSize, sortDirection)
      .then((response) => {
        setSchools(response);
        setLoadingStatus(false);
        setBlockActions(false);
      })
      .catch((error) => {
        setLoadingStatus(false);
        setBlockActions(false);
      });
  };

  useEffect(() => {
    //setLoadingStatus(true);
    setBlockActions(true);
    agent.Schools.count(schoolFilterQuery)
      .then((result) => {
        setTotalSchoolCount(result);
        loadSchools(currentPageIndex, false);
      })
      .catch((error) => {
        setLoadingStatus(false);
        setBlockActions(false);
      });
  }, [schoolFilterQuery, currentPageIndex, sortDirection, pageSize]);

  const changePageSize = (size) => {
    setPageSize(size);
  };

  const editSchool = (school) => {
    setSelectedSchool(school);
    setEditMode(true);
  };

  const editScreenCallBack = (forceRefresh) => {
    setEditMode(false);
    if (forceRefresh) loadSchools();
  };

  const openCreateNewSchool = () => {
    setEditMode(true);
    setSelectedSchool(null);
  };

  const deleteSchool = (school) => {
    setSelectedSchool(school);
    setConfirmDeleteSchool(true);
  };

  const confirmDeleteSchoolCallBack = () => {
    setIsUpdating(true);
    agent.Schools.delete(selectedSchool.id)
      .then((result) => {
        setSelectedSchool(null);
        setConfirmDeleteSchool(false);
        setIsUpdating(false);
        loadSchools();
      })
      .catch((error) => {
        toast.error("failed to deleted school");
        setIsUpdating(false);
      });
  };

  const cancelDeleteSchoolCallBack = () => {
    setSelectedSchool(null);
    setConfirmDeleteSchool(false);
  };

  const searchSchools = (e) => {
    setCurrentPageIndex(1);
    setSchoolFilterQuery(e.target.value);
  };

  const loadingScreen = (
    <Dimmer active inverted>
      <Loader size="large">Please Wait...</Loader>
    </Dimmer>
  );

  const dimmer = (
    <Dimmer active inverted>
      <Loader size="large">Please Wait...</Loader>
    </Dimmer>
  );

  const confirmDeleteModal = (
    <Confirm
      header="You are about to delete the selected school."
      content={
        <Container className="content">
          <span style={{ color: "red" }}>WARNING:</span>
          {
            " All students will no longer be able to access the app and all of their App progress will also be deleted."
          }
        </Container>
      }
      open={confirmDeleteSchool}
      onCancel={() => cancelDeleteSchoolCallBack()}
      onConfirm={() => confirmDeleteSchoolCallBack()}
    />
  );

  const content = (
    <>
      <input
        type="text"
        id="username"
        name="username"
        style={{ width: 0, height: 0, visibility: "hidden", position: "absolute", left: 0, top: 0 }}
      />
      <input
        type="password"
        name="password"
        style={{ width: 0, height: 0, visibility: "hidden", position: "absolute", left: 0, top: 0 }}
      />
      <Container fluid>
        <Segment clearing className="page-header">
          <Grid className="main-page-top-header" columns="equal">
            <Grid.Row>
              <Grid.Column>
                <Table celled padded>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <Header as="h2" floated="left">
                          Manage Schools
                        </Header>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Input
                          onChange={(e) => searchSchools(e)}
                          value={schoolFilterQuery}
                          autoComplete="new-password"
                          name="searchSchools"
                          placeholder="Search schools..."
                          icon={
                            <Icon
                              name="delete"
                              link
                              onClick={() => {
                                setCurrentPageIndex(1);
                                setSchoolFilterQuery("");
                              }}
                            />
                          }
                        />
                      </Table.Cell>
                      <Table.Cell collapsing textAlign="right">
                        <Button
                          className="app-btn-primary"
                          floated="right"
                          primary
                          onClick={() => openCreateNewSchool()}
                        >
                          <Icon name="add" />
                          Add School
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Grid>
          <GridRow>
            <Grid.Column>
              <SchoolsList
                blockActions={blockActions}
                currentPage={currentPageIndex}
                currentSortDirection={sortDirection}
                pageSize={pageSize}
                totalSchoolCount={totalSchoolCount}
                schools={schools}
                onSchoolSelected={(s) => {
                  editSchool(s);
                }}
                onDelete={(school) => deleteSchool(school)}
                onPageChanged={(pageIndex, direction) => {
                  setCurrentPageIndex(pageIndex);
                  setSortDirection(direction);
                }}
              />
              <div className="items-count">
                <span>Items per page:</span>
                <Dropdown className="paging-dd" text={pageSize.toString()}>
                  <Dropdown.Menu>
                    <Dropdown.Item text="25" onClick={() => changePageSize(25)} />
                    <Dropdown.Item text="50" onClick={() => changePageSize(50)} />
                    <Dropdown.Item text="100" onClick={() => changePageSize(100)} />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {confirmDeleteSchool && confirmDeleteModal}
            </Grid.Column>
          </GridRow>
        </Grid>
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          direction="right"
          vertical
          width="very wide"
          visible={editMode}
        >
          <Container>
            <div className="sidebar-close">
              <Button basic icon floated="right" onClick={() => editScreenCallBack(false)}>
                <Icon name="close" />
              </Button>
            </div>
            <Segment>
              <div className="sidebar-content">
                {editMode && (
                  <AddEditSchool
                    school={selectedSchool}
                    onClose={(refresh) => editScreenCallBack(refresh)}
                  />
                )}
              </div>
            </Segment>
          </Container>
        </Sidebar>
      </Container>
    </>
  );

  return (
    <>
      {isLoading && loadingScreen}
      {!isLoading && !isUpdating && content}
      {isUpdating && dimmer}
    </>
  );
}

export default observer(ManageSchools);
