import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Input,
  Menu,
  Sidebar,
  Dimmer,
  Loader,
  Segment,
  Confirm,
  Modal,
  Dropdown,
  Form,
  Table,
} from "semantic-ui-react";
import agent from "../../api/agent";
import SchoolLicenseSummary from "./SchoolLicenseSummary";
import StudentsList from "./StudentsList";
import AddEditUser from "./AddEditUser";
import { useStore } from "../../Stores/store";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import BulkUserUpload from "./BulkUserUpload";
import { toast } from "react-toastify";
import { FaUserLock, FaUserCheck, FaUserSlash, FaFileExport, FaUser, FaUserTimes } from "react-icons/fa";

export default observer(function ManageUsers(props) {
  let { schoolId } = useParams();
  const [pageSize, setPageSize] = useState(25);
  const { schoolStore } = useStore();
  const [students, setStudents] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [userFilterQuery, setUserFilterQuery] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setLoadingStatus] = useState(true);
  const [isupdating, setIsUpdating] = useState(false);
  const [licenseSummary, setLicenseSummary] = useState(false);
  const [confirmDeleteUser, setConfirmDeleteUser] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmedPassword, setConfirmedPassword] = useState(null);
  const [updatigUser, setUpdatingUser] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [blockActions, setBlockActions] = useState(false);

  useEffect(() => {
    setLoadingStatus(true);
    agent.Schools.details(schoolId).then((result) => {
      schoolStore.setSelectedSchool(result);
    });
    loadSchoolLicenseSummary();
    loadUsers();
  }, [schoolId]);

  useEffect(() => {
    setBlockActions(true);
    agent.Students.count(schoolId, userFilterQuery).then((result) => {
      setTotalUserCount(result);
      loadUsers(false);
    });
  }, [userFilterQuery, currentPageIndex, pageSize]);

  const loadSchoolLicenseSummary = () => {
    agent.Schools.getLicenseSummary(schoolId).then((result) => {
      setLicenseSummary(result);
    });
  };

  const searchUsers = (e) => {
    setCurrentPageIndex(1);
    setUserFilterQuery(e.target.value);
  };

  const exportSchoolUsers = () => {
    agent.Students.export(schoolStore.current.id);
  };

  const loadUsers = (showProgress = true) => {
    if (showProgress) setLoadingStatus(true);
    agent.Students.list(schoolId, userFilterQuery, currentPageIndex, pageSize).then((result) => {
      setStudents(result);
      setLoadingStatus(false);
      setBlockActions(false);
    });
  };

  const changePageSize = (size) => {
    setPageSize(size);
    setCurrentPageIndex(1);
    //loadUsers();
  };

  const changeUserStatus = (status) => {
    if (selectedUsers.length === 0) {
      toast.success("No user selected");
      return;
    }
    setIsUpdating(true);
    var ids = selectedUsers.map((u) => u.id);
    agent.Students.updateStatus(ids, status)
      .then((result) => {
        setIsUpdating(false);
        loadUsers();
        loadSchoolLicenseSummary();
        setSelectedUsers([]);
        setCurrentPageIndex(1);
      })
      .catch((error) => {
        setIsUpdating(false);
      });
  };

  const editScreenCallBack = (forceRefresh) => {
    setEditMode(false);
    if (forceRefresh) {
      loadUsers();
      loadSchoolLicenseSummary();
    }
  };

  const confirmDeleteUserCallBack = () => {
    if (selectedUsers.length === 0) {
      toast.success("No user selected");
      return;
    }
    setIsUpdating(true);
    setConfirmDeleteUser(false);
    agent.Students.delete(selectedUsers)
      .then((result) => {
        setConfirmDeleteUser(false);
        setCurrentPageIndex(1);
        loadSchoolLicenseSummary();
        agent.Students.count(schoolId, userFilterQuery).then((result) => {
          setTotalUserCount(result);
          loadUsers(true);
          setIsUpdating(false);
        });
      })
      .catch((error) => {
        setLoadingStatus(false);
        setIsUpdating(false);
        setConfirmDeleteUser(false);
      });
  };

  const cancelDeleteUserCallBack = () => {
    setConfirmDeleteUser(false);
  };

  const confirmDeleteModal = (
    <Confirm
      className="delete-popup"
      header="You are about to delete the selected user(s)."
      content="WARNING: The user(s) will no longer be able to access the app and all of their progress will be deleted."
      open={confirmDeleteUser}
      onCancel={() => cancelDeleteUserCallBack()}
      onConfirm={() => confirmDeleteUserCallBack()}
    />
  );

  const resetUserPassword = () => {
    if (selectedUsers.length === 0) {
      toast.success("No user selected");
      return;
    }
    setUpdatingUser(true);
    agent.Students.resetPassword(selectedUsers[0].id, newPassword).then((result) => {
      setUpdatingUser(false);
      setNewPassword(null);
      setConfirmedPassword(null);
      setShowResetPasswordModal(false);
      toast.success("User password was reset sucessfully");
    });
  };

  const resetPasswordModal = (
    <Modal open={showResetPasswordModal}>
      <Modal.Header>Reset User Password</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form className="pass-reset">
            <Form.Group widths="equal">
              <Form.Input
                label="New Password"
                error="Minimum password requirement: 4 characters"
                type={showPassword ? "text" : "password"}
                autoComplete="new-password"
                onChange={(event) => {
                  setNewPassword(event.target.value);
                }}
                icon={
                  <Icon
                    name={showPassword ? "eye slash" : "eye"}
                    link
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  />
                }
              />
              <Form.Input
                label="Confirm Password"
                autoComplete="new-password"
                onChange={(event) => {
                  setConfirmedPassword(event.target.value);
                }}
                type={showConfirmedPassword ? "text" : "password"}
                icon={
                  <Icon
                    name={showConfirmedPassword ? "eye slash" : "eye"}
                    link
                    onClick={() => {
                      setShowConfirmedPassword(!showConfirmedPassword);
                    }}
                  />
                }
              />
            </Form.Group>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          disabled={updatigUser}
          onClick={() => {
            setShowResetPasswordModal(false);
            setShowConfirmedPassword(false);
            setShowPassword(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={newPassword == null || newPassword === "" || newPassword !== confirmedPassword}
          color="green"
          onClick={() => {
            resetUserPassword();
            setShowConfirmedPassword(false);
            setShowPassword(false);
          }}
          positive
          loading={updatigUser}
        >
          Reset
        </Button>
      </Modal.Actions>
    </Modal>
  );

  const loadingScreen = (
    <Dimmer active inverted>
      <Loader size="large">Please Wait...</Loader>
    </Dimmer>
  );

  const dimmer = (
    <Dimmer active inverted className="mng-usr-loading">
      <Loader size="large">Please Wait...</Loader>
    </Dimmer>
  );

  const content = (
    <>
      <input
        type="text"
        id="username"
        style={{ width: 0, height: 0, visibility: "hidden", position: "absolute", left: 0, top: 0 }}
      />
      <input
        type="password"
        style={{ width: 0, height: 0, visibility: "hidden", position: "absolute", left: 0, top: 0 }}
      />
      {showResetPasswordModal && resetPasswordModal}
      <Container fluid>
        <Segment clearing className="page-header school-details user-details">
          <Grid className="main-page-top-header" columns="equal">
            <Grid.Row className="user-info-tablerow">
              <Grid.Column>
                <Table celled padded>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className="user-info-td school-name">
                        <Header floated="left" as="h2">
                          {schoolStore.current != null && schoolStore.current.name}
                        </Header>
                        <div className="user-info-container">
                          <div className="user-info">
                            <Header as="h4">Email:</Header>
                            {schoolStore.current != null && schoolStore.current.contactEmail}
                          </div>
                          <div className="user-info">
                            <Header as="h4">Expires on:</Header>
                            {schoolStore.current != null &&
                              schoolStore.current.licenseExpiryDateFormated}
                          </div>
                        </div>
                      </Table.Cell>
                      <Table.Cell collapsing textAlign="right" className="school-stats">
                        <SchoolLicenseSummary schoolLicenseSummary={licenseSummary} />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment className="mng-usrs">
          <Container fluid>
            <Header as="h2" floated="left">
              Manage Users
            </Header>
          </Container>
          <Container className="mng-btn">
            <Button
              className="new-btn"
              color="green"
              onClick={() => {
                setEditMode(true);
              }}
            >
              <FaUser />
              {"Add User"}
            </Button>
            <Button
              className="reset-btn"
              disabled={selectedUsers.length !== 1}
              onClick={() => {
                setShowResetPasswordModal(true);
              }}
            >
              <FaUserLock />
              {"Reset Password"}
            </Button>
            <Button
              className="delete-btn"
              disabled={selectedUsers.length === 0}
              onClick={() => setConfirmDeleteUser(true)}
            >
              <FaUserTimes />
              {"Delete"}
            </Button>
            {/*<Button
              className="deactivate-btn"
              disabled={
                selectedUsers.length === 0 || (selectedUsers.length === 1 && !selectedUsers[0].isActive)
              }
              onClick={() => changeUserStatus(false)}
            >
              <FaUserSlash />
              {"Deactivate"}
            </Button>*/}
            {/* <Button
              className="reactivate-btn"
              disabled={
                selectedUsers.length === 0 || (selectedUsers.length === 1 && selectedUsers[0].isActive)
              }
              onClick={() => changeUserStatus(true)}
            >
              <FaUserCheck />
              {"Reactivate"}
            </Button> */}
            <Button className="exp-btn" basic onClick={exportSchoolUsers}>
              <FaFileExport />
            </Button>
            <Input
              placeholder="Search Users..."
              onChange={(e) => searchUsers(e)}
              autoComplete="new-password"
              value={userFilterQuery}
              icon={
                <Icon
                  name="delete"
                  link
                  onClick={() => {
                    setCurrentPageIndex(1);
                    setUserFilterQuery("");
                  }}
                />
              }
            />
          </Container>
        </Segment>
        <Grid>
          <Grid.Column>
            <StudentsList
              students={students}
              onSelectedUserChanged={(users) => {
                setSelectedUsers(users);
              }}
              currentPage={currentPageIndex}
              pageSize={pageSize}
              totalUserCount={totalUserCount}
              onPageChanged={(pageIndex) => {
                setCurrentPageIndex(pageIndex);
              }}
              blockActions={blockActions}
            />
            <div className="items-count">
              <span>Items per page:</span>
              <Dropdown className="paging-dd" text={pageSize.toString()} disabled={blockActions}>
                <Dropdown.Menu>
                  <Dropdown.Item text="25" onClick={() => changePageSize(25)} />
                  <Dropdown.Item text="50" onClick={() => changePageSize(50)} />
                  <Dropdown.Item text="100" onClick={() => changePageSize(100)} />
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {confirmDeleteUser && confirmDeleteModal}
          </Grid.Column>
        </Grid>
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          direction="right"
          vertical
          width="very wide"
          visible={editMode}
        >
          <Container>
            <div className="sidebar-close">
              <Button
                basic
                icon
                floated="right"
                onClick={() => {
                  setEditMode(false);
                }}
              >
                <Icon name="close" />
              </Button>
            </div>
            <Segment>
              <div className="sidebar-header">
                <Header as="h2" floated="left" className="sidebar-new-user">
                  New User
                </Header>
              </div>
              <div className="sidebar-content">
                {
                  <AddEditUser
                    onClose={(refresh) => {
                      editScreenCallBack(refresh);
                    }}
                  />
                }
              </div>
            </Segment>
            <BulkUserUpload schoolId={schoolId} />
          </Container>
        </Sidebar>
      </Container>
    </>
  );

  return (
    <>
      {isLoading && loadingScreen}
      {!isLoading && content}
      {isupdating && dimmer}
    </>
  );
});
