import React, { useState } from "react";
import { Segment, Header, Container, List, Button, Label } from "semantic-ui-react";
import Papa from "papaparse";
import ReactFileReader from "react-file-reader";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../Stores/store";
import agent from "../../api/agent";
import { toast } from "react-toastify";

export default observer(function BulkUserUpload(props) {
  const [uploadReady, setUploadReady] = useState(false);
  const { bulkUploadStore } = useStore();
  let { schoolId } = useParams();
  const downloadTemplate = () => {
    var link = document.createElement("a");
    link.download = "template.csv";
    link.href = "/assets/template.csv";
    link.click();
  };

  const handleFileUpload = (files) => {
    if (files) {
      Papa.parse(files[0], {
        complete: function (results) {
          var users = getUsersArray(results.data);
          if (users == null || users.length === 0) {
            toast.error("Invalid file format");
          } else {
            agent.Schools.getLicenseSummary(schoolId)
              .then((result) => {
                if (users.length > result.remainingLicenseCount) {
                  toast.error("Not enough licenses remaining");
                } else {
                  setUploadReady(true);
                  bulkUploadStore.setUsersList(users);
                }
              })
              .catch((error) => {
                toast.error("Something went wrong, please try again.");
              });
          }
        },
      });
    }
  };

  const getUsersArray = (csv) => {
    if (csv.length <= 1) return null;
    var jsonUsers = [];
    csv.forEach((element, index) => {
      if (index > 0 && element[0] && element[1]) {
        var user = {
          userName: element[0],
          password: element[1],
          schoolId: schoolId,
          uploadStatus: "Pending",
          newUserName: element[0],
        };
        jsonUsers.push(user);
      }
      if (element[0] && element[0].length < 5) {
        toast.error(
          "User " +
            element[0] +
            " has a short username. Please make sure that all users have a username of minimum 5 characters and try again."
        );
        setUploadReady(false);
        return null;
      }
      if (element[1] && element[1].length < 4) {
        toast.error(
          "User " +
            element[0] +
            " has a short password. Please make sure that all users have a password of minimum 4 characters and try again."
        );
        setUploadReady(false);
        return null;
      }
    });
    return jsonUsers;
  };

  return (
    <>
      <Segment className="newuser-upload">
        <div className="sidebar-header ui">
          <Header as="h2" floated="left" className="">
            New Users (Bulk Upload)
          </Header>
        </div>
        <div className="sidebar-content" floated="left">
          <Container>
            <List className="bulk-steps" floated="left">
              <List.Item as="li">
                <Label color="teal" pointing="right">
                  1
                </Label>
                Download Template.
              </List.Item>
              <List.Item as="li">
                <Button className="dl-btn bulk-btn" basic compact onClick={downloadTemplate}>
                  Download
                </Button>
              </List.Item>
              <List.Item as="li">
                <Label color="teal" pointing="right">
                  2
                </Label>
                <p>
                  Add usernames to column 1. <br></br>Add passwords to column 2.<br></br> Do not change
                  column headers.{" "}
                </p>
              </List.Item>
              <List.Item as="li">
                <Label color="teal" pointing="right">
                  3
                </Label>
                <p>Save template.</p>
              </List.Item>
              <List.Item as="li">
                <Label color="teal" pointing="right">
                  4
                </Label>
                <p>Select template below and select upload.</p>
              </List.Item>
              <List.Item as="li">
                <ReactFileReader handleFiles={handleFileUpload} fileTypes=".csv,.xlsx,.xls">
                  <Button className="bulk-btn" basic compact>
                    Select Template
                  </Button>
                </ReactFileReader>
              </List.Item>
            </List>
          </Container>
        </div>

        <Button disabled={!uploadReady} floated="right" as={NavLink} to={`/${schoolId}/bulkupload`}>
          Upload
        </Button>
      </Segment>
    </>
  );
});
