import React, { useState } from "react";
import { Image, Container, Header, Card, Button, Icon } from "semantic-ui-react";
import { HashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";

export default function WordsPracticed() {
  const [showMoreJungleStories, setShowMoreJungleStories] = useState(false);
  const [showMoreDesertStories, setShowMoreDesertStories] = useState(false);
  const [showMoreArcticStories, setShowMoreArcticStories] = useState(false);
  const [showMoreSavannahStories, setShowMoreSavannahStories] = useState(false);
  const [showMoreDinosaurStories, setShowMoreDinosaurStories] = useState(false);

  return (
    <>
      <div className="WordsPracticed">
        <NavLink to="/">
          <Button className="back-to-btn" color="yellow" floated="right">
            Back to Homepage
          </Button>
        </NavLink>
        <Header as="h2">Stories and Words Practiced</Header>
        <Container className="main-islands">
          <Header as="h2" textAlign="left">
            Islands (WORDS BY ISLAND)
          </Header>
          <Card.Group itemsPerRow={5}>
            <Card className="jungle">
              <HashLink smooth to="/studentscenter/WordsPracticed#jungle">
                <Image src="/assets/1-Jungle.png" size="small" wrapped ui={false} />
                <Card.Content>
                  <Card.Header>Jungle</Card.Header>
                </Card.Content>
              </HashLink>
            </Card>
            <Card className="desert">
              <HashLink smooth to="/studentscenter/WordsPracticed#desert">
                <Image src="/assets/2-Desert-1.png" size="small" wrapped ui={false} />
                <Card.Content>
                  <Card.Header>Desert</Card.Header>
                </Card.Content>
              </HashLink>
            </Card>
            <Card>
              <HashLink smooth to="/studentscenter/WordsPracticed#arctic">
                <Image src="/assets/3-Arctic-1.png" size="small" wrapped ui={false} />
                <Card.Content>
                  <Card.Header>Arctic</Card.Header>
                </Card.Content>
              </HashLink>
            </Card>
            <Card className="savanna">
              <HashLink smooth to="/studentscenter/WordsPracticed#savannah">
                <Image src="/assets/4-Savanna-1.png" size="small" wrapped ui={false} />
                <Card.Content>
                  <Card.Header>Savannah</Card.Header>
                </Card.Content>
              </HashLink>
            </Card>
            <Card className="dino">
              <HashLink smooth to="/studentscenter/WordsPracticed#dino">
                <Image src="/assets/5-Dino-1.png" size="small" wrapped ui={false} />
                <Card.Content>
                  <Card.Header>Dinosaur</Card.Header>
                </Card.Content>
              </HashLink>
            </Card>
          </Card.Group>
        </Container>
        <Container id="jungle">
          <Header as="h2" textAlign="left">
            Jungle Island (BASIC)
          </Header>
          <Card.Group itemsPerRow={4}>
            <Card raised>
              <a href="/studentscenter/files/TI_L1_A01" target="_blank">
                <Image src="/assets/TI_L1_A01_ACoolContest.jpg" />
              </a>
            </Card>
            <Card raised>
              <a href="/studentscenter/files/TI_L1_A02" target="_blank">
                <Image src="/assets/TI_L1_A02_APerfectPicnic.jpg" />
              </a>
            </Card>
            <Card raised>
              <a href="/studentscenter/files/TI_L1_A03" target="_blank">
                <Image src="/assets/TI_L1_A03_ReturnOfTheRoar.jpg" />
              </a>
            </Card>
            <Card raised>
              <a href="/studentscenter/files/TI_L1_A04" target="_blank">
                <Image src="/assets/TI_L1_A04_ThePetProblem.jpg" />
              </a>
            </Card>
            {showMoreJungleStories && (
              <>
                <Card raised>
                  <a href="/studentscenter/files/TI_L1_A05" target="_blank">
                    <Image src="/assets/TI_L1_A05_ADragonsBestFriend.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L1_A06" target="_blank">
                    <Image src="/assets/TI_L1_A06_HolidayInEnchancia.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L1_A07" target="_blank">
                    <Image src="/assets/TI_L1_A07_WatchDug.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L1_A08" target="_blank">
                    <Image src="/assets/TI_L1_A08_AhoyIzzy.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L1_A09" target="_blank">
                    <Image src="/assets/TI_L1_A09_PetVet.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L1_A10" target="_blank">
                    <Image src="/assets/TI_L1_A10_RapunzelFindsAFriend.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L1_A11" target="_blank">
                    <Image src="/assets/TI_L1_A11_HappyToHelp.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L1_A12" target="_blank">
                    <Image src="/assets/TI_L1_A12_SuperAnnoying.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L1_A13" target="_blank">
                    <Image src="/assets/TI_L1_A13_CaseoftheMissingSparkleizer.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L1_A14" target="_blank">
                    <Image src="/assets/TI_L1_A14_XMarksTheCroc.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L1_A15" target="_blank">
                    <Image src="/assets/TI_L1_A15_SmittenWithAKitten.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L1_A16" target="_blank">
                    <Image src="/assets/TI_L1_A16_AFriendInNeed.jpg" />
                  </a>
                </Card>
              </>
            )}
          </Card.Group>
          <Button inverted color="blue" onClick={() => setShowMoreJungleStories(!showMoreJungleStories)}>
            More
            <Icon name={showMoreJungleStories ? "caret up" : "caret down"} />
          </Button>
        </Container>
        <Container id="desert">
          <Header as="h2" textAlign="left">
            Desert Island (DEVELOPING)
          </Header>
          <Card.Group itemsPerRow={4}>
            <Card raised>
              <a href="/studentscenter/files/TI_L2_A01" target="_blank">
                <Image src="/assets/TI_L2_A01_MickeyAndDonaldHaveAFarm.jpg" />
              </a>
            </Card>
            <Card raised>
              <a href="/studentscenter/files/TI_L2_A02" target="_blank">
                <Image src="/assets/TI_L2_A02_ThunderAndLightning.jpg" />
              </a>
            </Card>
            <Card raised>
              <a href="/studentscenter/files/TI_L2_A03" target="_blank">
                <Image src="/assets/TI_L2_A03_BuldasCrystal.jpg" />
              </a>
            </Card>
            <Card raised>
              <a href="/studentscenter/files/TI_L2_A04" target="_blank">
                <Image src="/assets/TI_L2_A04_FuliFindsHerPlace.jpg" />
              </a>
            </Card>
            {showMoreDesertStories && (
              <>
                <Card raised>
                  <a href="/studentscenter/files/TI_L2_A05" target="_blank">
                    <Image src="/assets/TI_L2_A05_TheMermaidDivesIn.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L2_A06" target="_blank">
                    <Image src="/assets/TI_L2_A06_TheGhostLightFish.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L2_A07" target="_blank">
                    <Image src="/assets/TI_L2_A07_TheEnchantedScienceFair.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L2_A08" target="_blank">
                    <Image src="/assets/TI_L2_A08_RaceForTheRigatoniRibbon.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L2_A09" target="_blank">
                    <Image src="/assets/TI_L2_A09_BowBotRobot.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L2_A10" target="_blank">
                    <Image src="/assets/TI_L2_A10_TheKeyToSkullRock.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L2_A11" target="_blank">
                    <Image src="/assets/TI_L2_A11_TheGreatCat-tastrophe.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L2_A12" target="_blank">
                    <Image src="/assets/TI_L2_A12_HueyDeweyandLouiesRainyDay.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L2_A13" target="_blank">
                    <Image src="/assets/TI_L2_A13_Run-DownRacecar.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L2_A14" target="_blank">
                    <Image src="/assets/TI_L2_A14_RoaringRivals.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L2_A15" target="_blank">
                    <Image src="/assets/TI_L2_A15_BelleAndThePerfectPearl.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L2_A16" target="_blank">
                    <Image src="/assets/TI_L2_A16_TheSupersSaveTheDay.jpg" />
                  </a>
                </Card>
              </>
            )}
          </Card.Group>
          <Button inverted color="blue" onClick={() => setShowMoreDesertStories(!showMoreDesertStories)}>
            More
            <Icon name={showMoreDesertStories ? "caret up" : "caret down"} />
          </Button>
        </Container>

        <Container id="arctic">
          <Header as="h2" textAlign="left">
            Arctic Island (DESCRIPTIVE)
          </Header>
          <Card.Group itemsPerRow={4}>
            <Card raised>
              <a href="/studentscenter/files/TI_L3_A01" target="_blank">
                <Image src="/assets/TI_L3_A01_Partysaurus-Rex.jpg" />
              </a>
            </Card>
            <Card raised>
              <a href="/studentscenter/files/TI_L3_A02" target="_blank">
                <Image src="/assets/TI_L3_A02_Melting-Hearts.jpg" />
              </a>
            </Card>
            <Card raised>
              <a href="/studentscenter/files/TI_L3_A03" target="_blank">
                <Image src="/assets/TI_L3_A03_Francescos-Rematch.jpg" />
              </a>
            </Card>
            <Card raised>
              <a href="/studentscenter/files/TI_L3_A04" target="_blank">
                <Image src="/assets/TI_L3_A04_The-Curse-of-Princess-Ivy.jpg" />
              </a>
            </Card>
            {showMoreArcticStories && (
              <>
                <Card raised>
                  <a href="/studentscenter/files/TI_L3_A05" target="_blank">
                    <Image src="/assets/TI_L3_A05_Super-Adventure.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L3_A06" target="_blank">
                    <Image src="/assets/TI_L3_A06_Aurora-and-the-Helpful-Dragon.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L3_A07" target="_blank">
                    <Image src="/assets/TI_L3_A07_Puppy-Trouble.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L3_A08" target="_blank">
                    <Image src="/assets/TI_L3_A08_One-Amazing-Chef.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L3_A09" target="_blank">
                    <Image src="/assets/TI_L3_A09_A-Tight-Squeeze.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L3_A10" target="_blank">
                    <Image src="/assets/TI_L3_A10_Elsas-Gift.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L3_A11" target="_blank">
                    <Image src="/assets/TI_L3_A11_Always-Time-For-A-Laugh.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L3_A12" target="_blank">
                    <Image src="/assets/TI_L3_A12_Belle-and-the-Castle-Puppy.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L3_A13" target="_blank">
                    <Image src="/assets/TI_L3_A13_Zootopia.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L3_A14" target="_blank">
                    <Image src="/assets/TI_L3_A14_It-Takes-Two.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L3_A15" target="_blank">
                    <Image src="/assets/TI_L3_A15_A-New-Chef-in-Town.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L3_A16" target="_blank">
                    <Image src="/assets/TI_L3_A16_A-Day-Without-Pumbaa.jpg" />
                  </a>
                </Card>
              </>
            )}
          </Card.Group>
          <Button inverted color="blue" onClick={() => setShowMoreArcticStories(!showMoreArcticStories)}>
            More
            <Icon name={showMoreArcticStories ? "caret up" : "caret down"} />
          </Button>
        </Container>
        <Container id="savannah">
          <Header as="h2" textAlign="left">
            Savannah Island (CONVERSATIONAL)
          </Header>
          <Card.Group itemsPerRow={4}>
            <Card raised>
              <a href="/studentscenter/files/TI_L4_A01" target="_blank">
                <Image src="/assets/TI_L4_A01_ADazzlingDay.jpg" />
              </a>
            </Card>
            <Card raised>
              <a href="/studentscenter/files/TI_L4_A02" target="_blank">
                <Image src="/assets/TI_L4_A02_PartyCentral.jpg" />
              </a>
            </Card>
            <Card raised>
              <a href="/studentscenter/files/TI_L4_A03" target="_blank">
                <Image src="/assets/TI_L4_A03_ElsasPerfectPlan.jpg" />
              </a>
            </Card>
            <Card raised>
              <a href="/studentscenter/files/TI_L4_A04" target="_blank">
                <Image src="/assets/TI_L4_A04_WingsAroundTheGlobe.jpg" />
              </a>
            </Card>
            {showMoreSavannahStories && (
              <>
                <Card raised>
                  <a href="/studentscenter/files/TI_L4_A05" target="_blank">
                    <Image src="/assets/TI_L4_A05_ASpecialSong.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L4_A06" target="_blank">
                    <Image src="/assets/TI_L4_A06_GoStitchGo.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L4_A07" target="_blank">
                    <Image src="/assets/TI_L4_A07_JasmineTheMatchmaker.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L4_A08" target="_blank">
                    <Image src="/assets/TI_L4_A08_DonaldTakesATrip.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L4_A09" target="_blank">
                    <Image src="/assets/TI_L4_A09_LittleBrothers.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L4_A10" target="_blank">
                    <Image src="/assets/TI_L4_A10_ASurpriseGuest.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L4_A11" target="_blank">
                    <Image src="/assets/TI_L4_A11_IsabelsSchoolAdventure.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L4_A12" target="_blank">
                    <Image src="/assets/TI_L4_A12_SugarRush.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L4_A13" target="_blank">
                    <Image src="/assets/TI_L4_A13_QuestForTheHeart.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L4_A14" target="_blank">
                    <Image src="/assets/TI_L4_A14_AnnaAndElsasChildhoodTimes.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L4_A15" target="_blank">
                    <Image src="/assets/TI_L4_A15_BabysittingMode.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L4_A16" target="_blank">
                    <Image src="/assets/TI_L4_A16_APerfectMatch.jpg" />
                  </a>
                </Card>
              </>
            )}
          </Card.Group>
          <Button
            inverted
            color="blue"
            onClick={() => setShowMoreSavannahStories(!showMoreSavannahStories)}
          >
            More
            <Icon name={showMoreSavannahStories ? "caret up" : "caret down"} />
          </Button>
        </Container>
        <Container id="dino">
          <Header as="h2" textAlign="left">
            Dinosaur Island (CONFIDENT)
          </Header>
          <Card.Group itemsPerRow={4}>
            <Card raised>
              <a href="/studentscenter/files/TI_L5_A01" target="_blank">
                <Image src="/assets/TI_L5_A01_PrideLandsPatrol.jpg" />
              </a>
            </Card>
            <Card raised>
              <a href="/studentscenter/files/TI_L5_A02" target="_blank">
                <Image src="/assets/TI_L5_A02_AnnaInCharge.jpg" />
              </a>
            </Card>
            <Card raised>
              <a href="/studentscenter/files/TI_L5_A03" target="_blank">
                <Image src="/assets/TI_L5_A03_MonsterLaughs.jpg" />
              </a>
            </Card>
            <Card raised>
              <a href="/studentscenter/files/TI_L5_A04" target="_blank">
                <Image src="/assets/TI_L5_A04_MeridaAndTheMissingGem.jpg" />
              </a>
            </Card>
            {showMoreDinosaurStories && (
              <>
                <Card raised>
                  <a href="/studentscenter/files/TI_L5_A05" target="_blank">
                    <Image src="/assets/TI_L5_A05_FightToTheFinish.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L5_A06" target="_blank">
                    <Image src="/assets/TI_L5_A06_SnowWhiteAndTheThreeGiants.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L5_A07" target="_blank">
                    <Image src="/assets/TI_L5_A07_OlafsNightBeforeChristmas.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L5_A08" target="_blank">
                    <Image src="/assets/TI_L5_A08_TianasFriendshipFixUp.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L5_A09" target="_blank">
                    <Image src="/assets/TI_L5_A09_DownToEarth.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L5_A10" target="_blank">
                    <Image src="/assets/TI_L5_A10_ElenaAndNaomisBigAdventure.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L5_A11" target="_blank">
                    <Image src="/assets/TI_L5_A11_RacingRivals.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L5_A12" target="_blank">
                    <Image src="/assets/TI_L5_A12_RapunzelsHeroes.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L5_A13" target="_blank">
                    <Image src="/assets/TI_L5_A13_ArielsDolphinAdventure.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L5_A14" target="_blank">
                    <Image src="/assets/TI_L5_A14_FindingDory.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L5_A15" target="_blank">
                    <Image src="/assets/TI_L5_A15_TheEvilQueen.jpg" />
                  </a>
                </Card>
                <Card raised>
                  <a href="/studentscenter/files/TI_L5_A16" target="_blank">
                    <Image src="/assets/TI_L5_A16_WhereMonstersLive.jpg" />
                  </a>
                </Card>
              </>
            )}
          </Card.Group>
          <Button
            inverted
            color="blue"
            onClick={() => setShowMoreDinosaurStories(!showMoreDinosaurStories)}
          >
            More
            <Icon name={showMoreDinosaurStories ? "caret up" : "caret down"} />
          </Button>
        </Container>
      </div>
    </>
  );
}
