import React from "react";
import { useStore } from "../../Stores/store";
import { Link } from "react-router-dom";
import { Button, Segment, Container, Header, Image } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import LoginForm from "../UserLogin/LoginForm";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default observer(function Home() {
  const { userStore, modalStore } = useStore();
  const history = useHistory();

  useEffect(() => {
    if (userStore.user && userStore.user.userRole === "LPADMIN") history.push("/myschools");
    if (userStore.user && userStore.user.userRole === "SCHOOLADMIN") {
      if (userStore.user.schoolId != null) history.push("/myschools/" + userStore.user.schoolId);
    }
    if (userStore.user && userStore.user.userRole === "STUDENT") history.push("/studentscenter");
  }, [userStore.user, history]);
  return (
    <>
      <Segment textAlign="center" vertical className="masthead">
        <Container text></Container>
        {userStore.isLoggedIn ? (
          <>
            <Header as="h2">Welcome to Learning Islands</Header>
            {userStore.user.userRole === "LPADMIN" && (
              <Button as={Link} to="/myschools" content="Go To Schools" size="huge" />
            )}
            {userStore.user.userRole === "SCHOOLADMIN" && (
              <Button
                as={Link}
                to={"/myschools/" + userStore.user.schoolId}
                content="Go To My School Dashboard"
                size="huge"
              />
            )}
            {userStore.user.userRole === "STUDENT" && (
              <Button as={Link} to="/studentscenter" content="Go To Student Dasboard" size="huge" />
            )}
          </>
        ) : (
          <div
            className="ui page modals dimmer visible active login-ui"
            style={{
              backgroundImage: `url(${"/assets/app_bg.svg"})`,
              backgroundSize: "cover",
              fontWeight: "normal",
              marginBottom: 0,
            }}
          >
            <div className="ui mini modal transition visible active">
              <div className="content">
                <Image src="/assets/LearningIslands.svg" fluid />
                <Button
                  className="ui fluid button"
                  onClick={() => {
                    modalStore.openModal(<LoginForm />);
                  }}
                  content="Login"
                  size="huge"
                />
              </div>
            </div>
          </div>
        )}
      </Segment>
    </>
  );
});
