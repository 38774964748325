import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

export default function LoadingComponent({ content }) {
  return (
    <>
      <Dimmer active inverted>
        <Loader size="large">{content}</Loader>
      </Dimmer>
    </>
  );
}
