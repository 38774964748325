import React, { useState } from "react";
import CustomTextInput from "../../common/CustomTextInput";
import { Formik, Form, ErrorMessage } from "formik";
import { Button, Label, Header, Icon } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../Stores/store";

export default observer(function LoginForm() {
  const { userStore } = useStore();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Formik
        initialValues={{ userName: "", password: "", error: null }}
        onSubmit={(values, { setErrors }) =>
          userStore.login(values).catch((error) => {
            setErrors({ error: "Invalid username or password" });
          })
        }
      >
        {({ handleSubmit, isSubmitting, errors }) => (
          <Form className="ui form login-form-li" onSubmit={handleSubmit} autoComplete="off">
            <Header as="h2" content="Login to Learning Islands" textAlign="center" />
            <CustomTextInput name="userName" placeholder="Username"></CustomTextInput>
            <CustomTextInput
              name="password"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              icon={
                <Icon
                  name={showPassword ? "eye slash" : "eye"}
                  link
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />
              }
            ></CustomTextInput>
            <ErrorMessage
              name="error"
              render={() => (
                <Label style={{ marginBottom: 10 }} basic color="red" content={errors.error} />
              )}
            />
            <Button loading={isSubmitting} positive content="Login" type="submit" fluid />
          </Form>
        )}
      </Formik>
    </>
  );
});
