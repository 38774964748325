import React from "react";
import { Container, Label, Menu, Segment } from "semantic-ui-react";

export default function SchoolLicenseSummary({ schoolLicenseSummary }) {
  return (
    <>
      <Container>
        <Segment>
          <Menu compact>
            <Menu.Item>
              <Label.Detail>Total Licenses</Label.Detail>
              <Label
                content={` ${
                  schoolLicenseSummary != null &&
                  schoolLicenseSummary.totalLicenseCount &&
                  schoolLicenseSummary.totalLicenseCount
                }`}
              />
            </Menu.Item>
            <Menu.Item>
              <Label.Detail>Used</Label.Detail>
              <Label
                content={` ${
                  schoolLicenseSummary != null &&
                  schoolLicenseSummary.usedLicensneCount != null &&
                  schoolLicenseSummary.usedLicensneCount
                }`}
              />
            </Menu.Item>
            <Menu.Item>
              <Label.Detail>Unused</Label.Detail>
              <Label
                content={` ${
                  schoolLicenseSummary != null &&
                  schoolLicenseSummary.remainingLicenseCount != null &&
                  schoolLicenseSummary.remainingLicenseCount
                }`}
              />
            </Menu.Item>
          </Menu>
        </Segment>
      </Container>
    </>
  );
}
