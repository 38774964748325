import { React } from "react";
import { useStore } from "../Stores/store";
import { Container, Dropdown, Image, Menu, Icon } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { NavLink, useHistory } from "react-router-dom";

export default observer(function TopNavigation(props) {
  const history = useHistory();
  const { userStore } = useStore();
  const {
    userStore: { user, logout },
  } = useStore();
  return (
    <>
      <Menu className="app-top-nav">
        <Menu.Item header as={NavLink} to="/">
          <Container className="mainheader" style={{ textAlign: "left" }}>
            <Image src="/assets/LearningIslands.svg" style={{ width: "120px" }} />
          </Container>
        </Menu.Item>
        <Menu.Menu position="right">
        <Menu.Item position="right">
              <NavLink to="/studentscenter">
              <Image src="/assets/LearningIslands_Plus.svg" style={{ width: "20px" }} />
              </NavLink>
            </Menu.Item>
          {userStore.user && userStore.user.userRole === "LPADMIN" && (
            <Menu.Item position="right">
              <NavLink to="/settings">
                <Icon name="setting" />
              </NavLink>
            </Menu.Item>
          )}
          <Menu.Item position="left">
            <Icon name="user circle" />
            <Dropdown pointing="top left" text={user?.displayName}>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    logout();
                    history.push("/");
                  }}
                  content="Logout"
                  icon="power"
                />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </>
  );
});
