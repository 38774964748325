import React from "react";
import { Image, Container, Header, Button, Grid, Embed } from "semantic-ui-react";
import { HashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";

export default function HowTo() {
  return (
    <>
      <div className="howto">
      <NavLink to="/">
        <Button className="back-to-btn" color='yellow' floated='right'>Back to Homepage</Button>
        </NavLink>
        <div>
        <Container fluid textAlign="left">
          <Header as="h2">How to use the Learning Islands App in your classroom</Header>
          <p>
            In the world of education, there are many works of research discussing the influence of
            emotions on learning, and unsurprisingly, the one that has been identified as key within all
            classrooms is enjoyment and therefore enjoyment of learning has been acknowledged by many as
            one of the key aims for educators and policymakers to improve children’s educational
            experience and outcomes<sup><HashLink smooth to="/studentscenter/HowTo#footnote">i</HashLink></sup> and reading enjoyment has been reported as more important for
            children’s educational success than their family’s socio-economic status<sup><HashLink smooth to="/studentscenter/HowTo#footnote">ii</HashLink></sup>.
          </p>
          <p>
            This is where our APP can be an exciting and enjoyable tool to support teachers and tutors
            with their existing curriculum and offer users a way to reinforce the work they do in class.
            The use of familiar characters and entertaining stories helps to engage and enthuse children
            and offers a way for them to develop their language skills whilst playing a range of fun,
            interactive games.
          </p>
          <p>
            With this in mind, the APP can be an excellent asset both in the classroom and out to enhance
            reading, writing and language acquisition. The texts can be used for a range of comprehension
            games and exercises and also as a stimulus for further writing and vocabulary development.
          </p>
        </Container>
        <Image className="" src="/assets/Asset-8@2x.png" centered />
      </div>
      <div>
        <Container fluid textAlign="left">
          <Header as="h2">Whole-class guided reading</Header>
          <p>
            At the most basic level of use, the APP can be used as a focus within a whole-class or small
            group guided reading session using a projector to screen the video text to a class then
            working through the questions one-by-one. This could be managed in a range of ways, either by
            asking volunteers, randomly selected individuals or larger groups to offer their answers.
          </p>
          <p>
            Guided reading is a practice based on Vygotsky’s (1978) Zone of Proximal Development and
            Bruner’s (1986) notion of scaffolding<sup><HashLink smooth to="/studentscenter/HowTo#footnote">iii</HashLink></sup>. This works on the premise that the best learning
            occurs when an educator assists the learners with reading strategies, guiding them through
            how to decode and analyse the text. Our games lend themselves perfectly to this, with the
            ability to rewind and reread the text, pre-written comprehension-based questions and a
            progressive framework so that texts can be allocated to children of different abilities.
          </p>
          <p>
            This could be further supported through use of a vocabulary bank or language log book whereby
            students record the words they discover through using the APP. This vocabulary can then be
            used for a range of additional activities, such as verb tense variation or synonym searching.
          </p>
          <p>
            Alternatively, the teacher could access the APP prior to the lesson and note down the
            vocabulary that will be covered and use this as a basis for the lesson focus itself. Within
            each land, there is a progression of vocabulary and often a common theme within questions
            which can be used as topics for specific language lessons, such as colour, size, family
            relationships, etc.
          </p>
        </Container>
        <Image className="" src="/assets/Asset-9@3x-100-Custom.jpg" fluid />
      </div>
      <div>
        <Container fluid textAlign="left">
          <Header as="h2">Individual and independent</Header>
          <p>
            The APP can also be used by students to support and reinforce their independent reading and
            language skills. Working through the APP on their own within a class can give them instant
            assessment and results which can be monitored and even recorded as a way to track and reward
            progress.
          </p>
          <p>
            Many schools in the UK use similar reading comprehension programmes to promote independent
            learning. Our APP is beneficial in that it incorporates characters the users will already be
            familiar with and therefore they will no doubt be more keen and enthusiastic to complete the
            activities.
          </p>
        </Container>
        <Image className="" src="/assets/Asset-8@2x.png" centered />
      </div>
      <div>
        <Container fluid textAlign="left">
          <Grid columns='equal'>
            <Grid.Column width={10}>
            <Header as="h2">Home and remote schooling</Header>
            <p>
            With a school subscription, educators could also allow their class to access the APP from
            home and thereby set activities which can be completed out of class as home or remote
            revision. Once again, by using texts the children will already want to read or watch means
            that they are more likely to complete the given task and this method of use can also allow
            opportunities for parental support and engagement.
          </p>
            </Grid.Column>
            <Grid.Column>
            <Image className="" src="/assets/Asset-10@2x-100.jpg" floated="right" size="large" />
            </Grid.Column>
          </Grid>
        </Container>
        <Container fluid textAlign="left">
        <Header as="h2">How do i...</Header>
          <Grid columns='equal' className="how-do-i">
            <Grid.Row>
            <Grid.Column>
              <Embed id='y6Q4TWQJ6Ck' placeholder='/assets/vid-1.png' source='youtube'/>
              <p className="video-title">
              <span>How do I …as a parent or teacher see children's progress?</span>
              <span> 1:21 min</span></p>
            </Grid.Column>
            <Grid.Column>
              <Embed id='T9FxUOV1whw' placeholder='/assets/vid-2.png' source='youtube'/>
              <p className="video-title">
              <span>How do I …get the children to see their own progress?</span>
              <span> 1:39 min</span>
              </p>
            </Grid.Column>
            <Grid.Column>
              <Embed id='oyhZJgrG1fU' placeholder='/assets/vid-3.png' source='youtube'/>
              <p className="video-title">
              <span>How do I …use Learning Islands with Children that have specific educational needs?</span>
              <span> 1:41 min</span></p>
            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid.Column>
              <Embed id='zxTOOb5um70' placeholder='/assets/vid-4.png' source='youtube'/>
              <p className="video-title">
              <span>How do I …use Learning Islands to help my students develop their English skills?</span>
              <span> 1:20 min</span></p>
            </Grid.Column>
            <Grid.Column>
              <Embed id='b1SUNjhSJR4' placeholder='/assets/vid-5.png' source='youtube'/>
              <p className="video-title">
              <span>How do I... use Learning Islands App in everyday English lessons?</span>
              <span> 2:07 min</span></p>
            </Grid.Column>
            <Grid.Column>
              <Embed id='0KEthk9jk5g' placeholder='/assets/vid-6.png' source='youtube'/>
              <p className="video-title">
              <span>How do I… use Learning Islands in a play group?</span>
              <span> 1:51 min</span></p>
            </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Container id="footnote" textAlign="left">
          <p>
          <sup>i</sup> Gorard, S. & See, B. H. How can we enhance enjoyment of secondary school? The student view. Br. Educ. Res. J. 37, 671–690 (2011).
          </p>
          <p>
          <sup>ii</sup><a href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/284286/reading_for_pleasure.pdf" target={"_blank"}> https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/284286/reading_for_pleasure.pdf</a>
          </p>
          <p> 
          <sup>iii</sup><a href="https://teacherofsci.com/vygotsky/" target={"_blank"}> https://teacherofsci.com/vygotsky/</a>
          </p>
        </Container>
      </div>
      </div>
    </>
  );
}
