import React, { useState, useEffect } from "react";
import { Button, Container, Grid, Header, Icon, Segment, Sidebar, Menu } from "semantic-ui-react";
import agent from "../../api/agent";
import AddAdmin from "./AddAdmin";
import AdminsList from "./AdminsList";

export default function AdminSettings() {
  const [admins, setAdmins] = useState(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    loadAdmins();
  }, []);

  const addAdminCallback = (refresh) => {
    if (refresh) loadAdmins();
    setEditMode(false);
  };

  const loadAdmins = () => {
    agent.Admins.list()
      .then((result) => {
        setAdmins(result);
      })
      .catch((error) => {});
  };

  return (
    <>
      <Container fluid>
        <Segment clearing className="page-header">
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column>
                <Header as="h2" floated="left">
                  Manage Administrators
                </Header>
              </Grid.Column>
              <Grid.Column></Grid.Column>

              <Grid.Column>
                <Button
                  className="app-btn-primary"
                  floated="right"
                  primary
                  onClick={() => setEditMode(true)}
                >
                  <Icon name="add" />
                  New Admin
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Grid>
          <Grid.Column>
            <AdminsList
              admins={admins}
              onUserDeleted={() => {
                loadAdmins();
              }}
            />
          </Grid.Column>
        </Grid>
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          direction="right"
          vertical
          width="very wide"
          visible={editMode}
        >
          <Container>
            <div className="sidebar-close">
              <Button basic icon floated="right" onClick={() => setEditMode(false)}>
                <Icon name="close" />
              </Button>
            </div>
            <Segment>
            <Header as="h2" floated="left" className="">
            New Admin
          </Header>
          {editMode && (
            <AddAdmin
              onClose={(refresh) => {
                addAdminCallback(refresh);
              }}
            />
          )}
          </Segment>
          </Container>
        </Sidebar>
      </Container>
    </>
  );
}
