import React from "react";
import { Link } from "react-router-dom";
import { Header, Segment, Icon, Button } from "semantic-ui-react";

export default function NotFound() {
  return (
    <>
      <Segment placeholder>
        <Header icon>
          <Icon name="search" />
          Oops - We've looked everywhere and could not find this
        </Header>
        <Segment.Inline>
          <Button as={Link} to="/" primary>
            Return to HomePage
          </Button>
        </Segment.Inline>
      </Segment>
    </>
  );
}
