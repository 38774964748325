import { createContext, useContext } from "react";
import SchoolStore from "./schoolStore";
import UserStore from "./userStore";
import CommonStore from "./commonStore";
import ModalStore from "./modalStore";
import BulkUploadStore from "./bulkUploadStore";

export const store = {
  schoolStore: new SchoolStore(),
  userStore: new UserStore(),
  commonStore: new CommonStore(),
  modalStore: new ModalStore(),
  bulkUploadStore: new BulkUploadStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
