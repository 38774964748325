import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useState } from "react";
import { Button, Container } from "semantic-ui-react";
import CustomTextInput from "../../common/CustomTextInput";
import { observer } from "mobx-react-lite";
import { useStore } from "../../Stores/store";
import agent from "../../api/agent";

export default observer(function AddEditUser({ selectedUser, onClose }) {
  const { schoolStore } = useStore();
  const [isSaving, setIsSaving] = useState(false);
  const initialState = selectedUser
    ? { ...selectedUser }
    : {
        userName: "",
        password: "",
        schoolId: 0,
      };

  const validationSchema = Yup.object({
    userName: Yup.string()
      .required("Username field is required")
      .min(5, "Username should be at least 5 characters"),
    password: Yup.string()
      .required("Password field is required")
      .min(4, "Password should be at least 4 characters"),
  });

  const submitForm = (userValues) => {
    userValues.schoolId = schoolStore.current.id;
    setIsSaving(true);
    agent.Students.create(userValues)
      .then((result) => {
        onClose(true);
        setIsSaving(false);
      })
      .catch((error) => {
        setIsSaving(false);
      });
  };

  return (
    <>
      <Container>
        <Formik
          enableReinitialize
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={(values) => submitForm(values)}
        >
          {({ values: user, handleChange, handleSubmit }) => (
            <Form className="ui form" autoComplete="off" onSubmit={handleSubmit}>
              <CustomTextInput
                placeholder="Username"
                name="userName"
                label="Username"
                autoComplete="new-password"
              />
              <CustomTextInput
                placeholder="Password"
                name="password"
                label="password"
                autoComplete="new-password"
              />
              <Button
                color="teal"
                floated="right"
                type="submit"
                positive
                content="Submit"
                loading={isSaving}
              />
              {!isSaving && (
                <Button
                  floated="right"
                  type="button"
                  content="Cancel"
                  onClick={() => {
                    onClose(false);
                  }}
                />
              )}
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
});
