import React from "react";
import { Image, Container, Header, Segment, Grid, Button } from "semantic-ui-react";
import { HashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";

export default function OurFramework() {
  return (
    <>
      <div className="OurFramework">
      <NavLink to="/">
        <Button className="back-to-btn" color='yellow' floated='right'>Back to Homepage</Button>
        </NavLink>
        <Header as="h2">Our Framework</Header>
        <Container fluid textAlign="left">
          <p>
            Although not exclusive and with a few variations or exceptions within each land, we have
            tried to incorporate a framework of language progression within the game to allow targeted
            use which will be very beneficial for classroom use<sup><HashLink smooth to="/studentscenter/OurFramework#footnote">iv</HashLink></sup>. These are outlined below, moving from
            basic nouns and adjectives, through to more complex nouns and verbs, finally ending with more
            unfamiliar words and colloquialisms:
          </p>

          <Segment inverted>
          <Grid>
           <Grid.Column width={5}>
             <Image src="/assets/1-Jungle.png" size="medium" verticalAlign="middle" floated="left" />{" "}
           </Grid.Column>
           <Grid.Column width={11}>
             <span>
              <strong>Jungle Island</strong> (BASIC) –<br></br> can recognise <strong><b>familiar words</b></strong> and <strong><b>very basic phrases</b></strong>
              concerning <strong><b>self, family, common nouns</b></strong> and <strong><b>adjectives</b></strong> including family names, colours,
              animals, size, body, e.g. brother, red, dog.
            </span>
           </Grid.Column>
          </Grid>
          </Segment>
        </Container>
        <Container fluid textAlign="left">
        <Segment inverted>
          <Grid>
           <Grid.Column width={5}>
             <Image src="/assets/2-Desert-1.png" size="medium" verticalAlign="middle" floated="left" />{" "}
           </Grid.Column>
           <Grid.Column width={11}>
             <span>
              <strong>Desert Island</strong> (DEVELOPING) –<br></br> can recognise more <strong><b>high frequency words</b></strong> and <strong><b>less common nouns</b></strong> including food, time,
              locations, etc. and <strong><b>basic adjectives</b></strong> and verbs, e.g. furry, spotty, walk, eat.
            </span>
           </Grid.Column>
          </Grid>
          </Segment>
        </Container>
        <Container fluid textAlign="left">
        <Segment inverted>
          <Grid>
           <Grid.Column width={5}>
             <Image src="/assets/3-Arctic-1.png" size="medium" verticalAlign="middle" floated="left" />{" "}
           </Grid.Column>
           <Grid.Column width={11}>
             <span>
              <strong>Arctic Island</strong> (DESCRIPTIVE) –<br></br> can recognise <strong><b>majority of high frequency words</b></strong> and <strong><b>stronger descriptive words</b></strong> relating to
              time, events, interests and feelings, e.g. afterwards, enjoy, excited.
            </span>
           </Grid.Column>
          </Grid>
          </Segment>
        </Container>
        <Container fluid textAlign="left">
        <Segment inverted>
          <Grid>
           <Grid.Column width={5}>
             <Image src="/assets/4-Savanna-1.png" size="medium" verticalAlign="middle" floated="left" />{" "}
           </Grid.Column>
           <Grid.Column width={11}>
             <span>
              <strong>Savannah Island</strong> (CONVERSATIONAL) –<br></br> can recognise <strong><b>less common words</b></strong> and <strong><b>extended phrases</b></strong> as well as <strong><b>understand opinion</b></strong> and more
              <strong><b>abstract nouns</b></strong>, e.g. dislike, disappointed, hunger, happiness.
            </span>
           </Grid.Column>
          </Grid>
          </Segment>
        </Container>
        <Container fluid textAlign="left">
        <Segment inverted>
          <Grid>
           <Grid.Column width={5}>
             <Image src="/assets/5-Dino-1.png" size="medium" verticalAlign="middle" floated="left" />{" "}
           </Grid.Column>
           <Grid.Column width={11}>
             <span>
              <strong>Dinosaur Island</strong> (CONFIDENT) –<br></br> can recognise <strong><b>majority of familiar and unfamiliar words</b></strong>. Can <strong><b>interpret implied meaning</b></strong> and
              <strong><b>colloquialisms</b></strong>, e.g. buddy, pal, uh-huh.
            </span>
           </Grid.Column>
          </Grid>
          </Segment>
        </Container>
        <Container fluid textAlign="left">
          <p>
            Ultimately, the Learning Islands App does not claim to teach directly, however there is no
            doubt that this application within a classroom setting has endless possibilities and will
            certainly support and reinforce existing teaching of language and learning through a fun and
            interactive modern method.
          </p>
        </Container>
        <Container id="footnote" textAlign="left">
          <p>
          <sup>iv</sup> Compiled by Lucy Weaver (English Consultant) March 2021 using a combination of the CEFR (Common European Framework for Reference) and the Bell Foundation EAL Assessment Framework for Schools)
https://www.cambridgeenglish.org/learning-english/parents-and-children/information-for-parents/tips-and-advice/011-the-cefr/
https://www.bell-foundation.org.uk/eal-programme/eal-assessment-framework/

          </p>
          <p>
          <a href="https://www.cambridgeenglish.org/learning-english/parents-and-children/information-for-parents/tips-and-advice/011-the-cefr/" target={"_blank"}> https://www.cambridgeenglish.org/learning-english/parents-and-children/information-for-parents/tips-and-advice/011-the-cefr/</a>
          </p>
          <p> 
          <a href="https://www.bell-foundation.org.uk/eal-programme/eal-assessment-framework/" target={"_blank"}> https://www.bell-foundation.org.uk/eal-programme/eal-assessment-framework/</a>
          </p>
        </Container>
      </div>
    </>
  );
}
